const PLUGINS = [
  {
    name: 'MS Project',
    description:
      'Import and export project progress data to your MS Project Files in a single click.',
    image: require('../images/plugins/ms-project.png'),
  },
  {
    name: 'BIM 360',
    description:
      'Monitor project from your BIM Drawing, simply link activities to the drawing elements, and track status in BOCS.',
    image: require('../images/plugins/bim.png'),
  },
  {
    name: 'Primavera',
    description:
      'Import and export project progress data to your Oracle Primavera Files in a single click.',
    image: require('../images/plugins/oracle.png'),
  },
  {
    name: 'Drone',
    description:
      'Visualize project progress on updated high resolution drone map.',
    image: require('../images/plugins/drone.png'),
  },
];

export default PLUGINS;

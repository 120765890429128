import React, { useState, useEffect, useCallback } from 'react';
import { range } from 'lodash-es';
import { useTransition, animated } from 'react-spring';
import classNames from 'classnames';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import Testimonial from '../Testimonial';

interface Props {
  testimonials: Array<{
    id: number;
    author: {
      name: string;
      profileImage: string;
      designation: string;
      company: string;
    };
    testimonial: string;
    image: string;
  }>;
}

const Testimonials: React.FC<Props> = ({ testimonials }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setActiveIndex(
        activeIndexState => (activeIndexState + 1) % testimonials.length,
      );
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [activeIndex, testimonials.length]);

  const transitions = useTransition(
    testimonials[activeIndex],
    testimonial => testimonial.id,
    {
      from: {
        transform: 'translate3d(100%, 0, 0)',
        opacity: 0,
      },
      enter: {
        transform: 'translate3d(0, 0, 0)',
        opacity: 1,
      },
      leave: {
        transform: 'translate3d(-50%, 0, 0)',
        opacity: 0,
      },
    },
  );

  const handlePrev = useCallback(() => {
    setActiveIndex(activeIndexState =>
      activeIndexState !== 0 ? (activeIndexState - 1) % testimonials.length : 0,
    );
  }, [testimonials.length]);

  const handleNext = useCallback(() => {
    setActiveIndex(
      activeIndexState => (activeIndexState + 1) % testimonials.length,
    );
  }, [testimonials.length]);

  return (
    <div>
      <div className="relative">
        {activeIndex !== 0 ? (
          <button
            className="p-2 absolute left-0 top-1/2 bg-gray-700 z-10 rounded text-gray-100 focus:outline-none"
            type="button"
            style={{
              transform: 'translate(-50%, -50%)',
            }}
            onClick={handlePrev}
          >
            <MdChevronLeft size={24} />
          </button>
        ) : null}
        {activeIndex !== testimonials.length - 1 ? (
          <button
            className="p-2 absolute right-0 top-1/2 bg-gray-700 z-10 rounded text-gray-100 focus:outline-none"
            type="button"
            style={{
              transform: 'translate(50%, -50%)',
            }}
            onClick={handleNext}
          >
            <MdChevronRight size={24} />
          </button>
        ) : null}
        <div className="h-125 md:h-80 relative overflow-hidden mb-6">
          {transitions.map(({ item, key, props }) => (
            <animated.div className="absolute inset-0" style={props} key={key}>
              <Testimonial testimonial={item} />
            </animated.div>
          ))}
        </div>
        <div className="flex items-center items-center justify-center">
          {range(testimonials.length).map(val => (
            <div
              key={val}
              className={classNames(
                'w-2 h-2 bg-white transition-opacity rounded-full',
                val !== testimonials.length - 1 ? 'mr-3' : undefined,
                val === activeIndex ? 'opacity-100' : 'opacity-25',
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;

import React, { useState, useCallback } from 'react';
import useForm from 'react-hook-form';
import validator from 'validator';
import classNames from 'classnames';
import { MdFileDownload } from 'react-icons/md';

import Modal from '../Modal';
import LeadGenerationForm from '../LeadGenerationForm';
import PRODUCTS from '../../data/products';

const PRODUCTS_WITH_BROCHURE = PRODUCTS.filter(product => !!product.brochure);

interface Props {}

const GetStarted: React.FC<Props> = () => {
  const {
    register,
    handleSubmit,
    errors,
    clearError,
    getValues,
    reset,
  } = useForm();

  const [visible, setVisible] = useState(false);

  const handleModalClose = useCallback(() => {
    setVisible(false);
    reset();
  }, [reset]);

  const handleDemoClick = useCallback(() => {
    setVisible(true);
  }, []);

  return (
    <>
      <button
        type="button"
        className="bg-gray-900 px-4 py-2 rounded text-lg text-gray-100 font-medium focus:outline-none"
        onClick={handleDemoClick}
      >
        Book a Demo
      </button>
      <Modal visible={visible} onDismiss={handleModalClose} title="Book a Demo">
        <>
          {visible ? (
            <LeadGenerationForm className="mb-2" onSuccess={handleModalClose} />
          ) : null}
          <div className="text-xs text-gray-500 text-center mb-2">
            Our team will get in touch with you in next 24 hours
          </div>
        </>
      </Modal>
    </>
  );
};

export default GetStarted;

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { animated, useTransition } from 'react-spring';

interface IconProps {
  className?: string;
  style?: React.CSSProperties;
}

interface Props {
  data: Array<{
    icon: React.ComponentClass<IconProps> | React.FC<IconProps>;
    title: string;
    image: string;
    caption: string;
  }>;
  light?: boolean;
  reverse?: boolean;
}

const FeaturesList: React.FC<Props> = ({ data, light, reverse }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const transitions = useTransition(data[activeIndex], item => item.image, {
    from: { opacity: 0, transform: 'translate3d(0%, 100%, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(0, -50%, 0)' },
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setActiveIndex(activeIndexState => (activeIndexState + 1) % data.length);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [data.length, activeIndex]);

  return (
    <div
      className={classNames('flex', reverse ? 'flex-row-reverse' : undefined)}
    >
      <div
        className={classNames(
          'flex flex-col justify-center',
          reverse ? 'ml-8' : 'mr-8',
        )}
      >
        {data.map((item, index) => {
          const { title, icon: Icon } = item;

          return (
            <div
              className={classNames(
                'cursor-pointer transition-opacity w-80 px-4 py-4 rounded select-none',
                index === activeIndex
                  ? classNames(
                      'opacity-100 shadow',
                      light ? 'bg-white border border-primary' : 'bg-gray-800',
                    )
                  : light
                  ? 'opacity-50'
                  : 'opacity-25',
                index !== data.length - 1 ? 'mb-8' : undefined,
              )}
              key={title}
              onMouseDown={() => {
                setActiveIndex(index);
              }}
            >
              <div className="flex items-center mb-4">
                <Icon className="w-6 h-6 mr-3" />
                <div
                  className={classNames(
                    'text-center text-lg',
                    light ? 'text-gray-800' : 'text-gray-300',
                    index === activeIndex ? 'font-medium' : 'font-regular',
                  )}
                >
                  {title}
                </div>
              </div>
              {index === activeIndex ? (
                <div
                  className={classNames(
                    'text-sm mt-2',
                    light ? 'text-gray-700' : 'text-gray-500',
                  )}
                >
                  {item.caption}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      <div
        className={classNames(
          'relative flex-1 h-100 xl:h-150 overflow-hidden',
          reverse ? '-ml-40 xl:-ml-80' : '-mr-40 xl:-mr-80',
        )}
      >
        {transitions.map(({ item, key, props }) => {
          return (
            <animated.div key={key} className="absolute inset-0" style={props}>
              <img
                src={item.image}
                alt={item.title}
                className="absolute inset-0 w-full h-full object-contain"
              />
            </animated.div>
          );
        })}
      </div>
    </div>
  );
};

FeaturesList.defaultProps = {
  light: false,
  reverse: false,
};

export default FeaturesList;

import React from 'react';

interface Props {
  testimonial: {
    author: {
      profileImage: string;
      name: string;
      designation: string;
      company: string;
    };
    testimonial: string;
    image: string;
  };
}

const Testimonial: React.FC<Props> = ({ testimonial }) => {
  return (
    <div className="rounded bg-white flex flex-col-reverse md:flex-row items-center overflow-hidden shadow-md h-125 md:h-auto">
      <div className="px-8 py-4">
        <div className="text-xl text-gray-800 font-medium leading-relaxed mb-6">
          {testimonial.testimonial}
        </div>
        <div className="w-2/12 border-b-2 border-gray-500 mb-6" />
        <div className="flex items-center">
          <img
            src={testimonial.author.profileImage}
            alt={testimonial.author.name}
            className="w-8 h-8 rounded-full mr-4"
          />
          <div>
            <div className="font-semibold text-sm text-gray-600">
              {testimonial.author.name}
            </div>
            <div className="text-xs text-gray-500 font-medium">
              {testimonial.author.designation} {testimonial.author.company}
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full md:w-auto">
        <svg
          viewBox="0 0 40 120"
          className="h-full absolute top-0 left-0 w-12 text-white hidden md:block"
          preserveAspectRatio="none"
          style={{ marginLeft: -2 }}
        >
          <path d="M0 0 L40 0 L0 120 Z" className="fill-current" />
        </svg>
        <img
          src={testimonial.image}
          className="w-full md:w-64 md:min-w-64 lg:w-100 lg:min-w-100 h-64 md:h-80 object-cover object-top"
          alt={testimonial.author.name}
        />
      </div>
    </div>
  );
};

export default Testimonial;

import React from 'react';
import classNames from 'classnames';

import NavBar from '../components/NavBar';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import FeaturesList from '../components/FeaturesList';
import MultilevelDashboard from '../images/icons/multilevel-dashboard.inline.svg';
import PhysicalProgress from '../images/icons/physical-progress.inline.svg';
import FinancialProgress from '../images/icons/financial-progress.inline.svg';
import GanttChart from '../images/icons/gantt-chart.inline.svg';
import Issue from '../images/icons/issue.inline.svg';
import Drive from '../images/icons/drive.inline.svg';
import Drawing from '../images/icons/drawing.inline.svg';
import PLUGINS from '../data/plugins';
import TESTIMONIALS from '../data/testimonials';
import PRODUCTS from '../data/products';
import { SHORT_FEATURES } from '../data/features';
import Testimonials from '../components/Testimonials';
import { CLIENTS } from '../data/clients';
import useMedia from '../hooks/useMedia';
import FeaturesListMobile from '../components/FeaturesListMobile';
import GetStarted from '../components/GetStarted';
import BrochureButton from '../components/BrochureButton';
import FooterLeadGenerationForm from '../components/FooterLeadGenerationForm';

const Home = () => {
  const { isMobile } = useMedia();

  return (
    <>
      <SEO title="BOCS - Construction Project Monitoring" />
      <Layout>
        <NavBar />
        <div className="w-full relative bg-primary xl:h-screen flex flex-col relative">
          <img
            src={require('../images/background.png')}
            alt=""
            className="absolute bottom-0 right-0 w-screen h-screen opacity-10"
          />
          <div className="max-w-xs md:max-w-4xl mx-auto flex flex-col items-center py-20 z-10">
            <div className="text-white text-2xl md:text-4xl xl:text-5xl font-bold mb-8 text-center">
              Monitor Multiple Construction Projects <br />
              on a Single Platform
            </div>

            <div className="flex flex-col md:flex-row mb-8 md:mb-12 items-center">
              {SHORT_FEATURES.map((feature, index) => (
                <React.Fragment key={feature}>
                  <div
                    className={classNames(
                      'text-lg text-blue-200 font-medium tracking-wide mb-2 md:mb-0',
                      index !== SHORT_FEATURES.length - 1 ? 'mr-4' : undefined,
                    )}
                  >
                    {feature}
                  </div>
                  {index !== SHORT_FEATURES.length - 1 ? (
                    <div className="hidden md:block w-1 h-1 rounded-full mr-4 bg-blue-100" />
                  ) : null}
                </React.Fragment>
              ))}
            </div>

            <GetStarted />
          </div>
          <div className="hidden md:block h-56 lg:h-80 flex-0 xl:flex-1 xl:h-auto relative z-10">
            <img
              src={require('../images/hero.png')}
              alt="BOCS"
              className="absolute inset-0 object-bottom object-contain w-full h-full"
            />
          </div>
          <div className="block md:hidden h-screen-1/2 overflow-hidden">
            <img src={require('../images/hero-mobile.png')} alt="BOCS" />
          </div>
        </div>

        <div className="pb-0 md:pb-16 pt-16 bg-gray-900" id="features">
          <div className="max-w-xs md:max-w-2xl lg:max-w-3xl xl:max-w-5xl mx-auto">
            <div className="text-2xl md:text-3xl lg:text-4xl font-black text-gray-100 text-center mb-8 md:mb-2">
              Track Projects in Real Time
            </div>
            <div className="text-lg text-center text-gray-500 mb-8 hidden md:block">
              Get detailed status of your projects at your fingertips
            </div>
            <div className="hidden md:block">
              <FeaturesList
                data={[
                  {
                    title: 'Multi-level Dashboard',
                    icon: MultilevelDashboard,
                    image: require('../images/features/multilevel-dashboard.png'),
                    caption:
                      'Get a quick overview of the overall status of a project',
                  },
                  {
                    title: 'Physical Progress',
                    icon: PhysicalProgress,
                    image: require('../images/features/physical-progress.png'),
                    caption:
                      'Get detailed status reports and analytics of the physical progress of a project',
                  },
                  {
                    title: 'Financial Progress',
                    icon: FinancialProgress,
                    image: require('../images/features/financial-progress.png'),
                    caption:
                      'Track all the bills and compare them with the physical progress',
                  },
                  {
                    title: 'Live Bar Chart',
                    icon: GanttChart,
                    image: require('../images/features/bar-chart.png'),
                    caption:
                      'A Bar Chart that updates itself automatically from Field App data.',
                  },
                ]}
              />
            </div>
            <div className="block md:hidden">
              {isMobile ? (
                <FeaturesListMobile
                  data={[
                    {
                      title: 'Multi-level Dashboard',
                      icon: MultilevelDashboard,
                      image: require('../images/features/multilevel-dashboard-mobile.png'),
                      caption:
                        'Get a quick overview of the overall status of a project',
                    },
                    {
                      title: 'Physical Progress',
                      icon: PhysicalProgress,
                      image: require('../images/features/physical-progress-mobile.png'),
                      caption:
                        'Get detailed status reports and analytics of the physical progress of a project',
                    },
                    {
                      title: 'Financial Progress',
                      icon: FinancialProgress,
                      image: require('../images/features/financial-progress-mobile.png'),
                      caption:
                        'Track all the bills and compare them with the physical progress',
                    },
                    {
                      title: 'Live Bar Chart',
                      icon: GanttChart,
                      image: require('../images/features/bar-chart-mobile.png'),
                      caption:
                        'A Bar Chart that updates itself automatically from Field App data.',
                    },
                  ]}
                />
              ) : null}
            </div>
          </div>
        </div>

        <div className="pb-0 md:pb-16 pt-16 bg-white">
          <div className="max-w-xs md:max-w-2xl lg:max-w-3xl xl:max-w-5xl mx-auto">
            <div className="text-2xl md:text-3xl lg:text-4xl font-black text-gray-800 text-center mb-8 md:mb-2">
              Enable Seamless Collaboration
            </div>
            <div className="text-lg text-center text-gray-600 mb-8 hidden md:block">
              Enable active sharing & communication among managers and field
              supervisors
            </div>
            <div className="hidden md:block">
              <FeaturesList
                light
                reverse
                data={[
                  {
                    icon: Issue,
                    title: 'Issue Management',
                    caption:
                      'Raise issue, assign team members and get them resolved quickly',
                    image: require('../images/features/issue.png'),
                  },
                  {
                    icon: Drawing,
                    title: 'Drawing Sharing',
                    caption:
                      'Equip field supervisors with the updated drawings',
                    image: require('../images/features/drawing.png'),
                  },
                  {
                    icon: Drive,
                    title: 'BOCS Drive',
                    caption:
                      'Organise all the project files and documents created during the project lifecycle',
                    image: require('../images/features/drive.png'),
                  },
                ]}
              />
            </div>
            <div className="block md:hidden">
              {isMobile ? (
                <FeaturesListMobile
                  light
                  data={[
                    {
                      icon: Issue,
                      title: 'Issue Management',
                      caption:
                        'Raise issue, assign team members and get them resolved quickly',
                      image: require('../images/features/issue-mobile.png'),
                    },
                    {
                      icon: Drawing,
                      title: 'Drawing Sharing',
                      caption:
                        'Equip field supervisors with the updated drawings',
                      image: require('../images/features/drawing-mobile.png'),
                    },
                    {
                      icon: Drive,
                      title: 'BOCS Drive',
                      caption:
                        'Organise all the project files and documents created during the project lifecycle',
                      image: require('../images/features/drive-mobile.png'),
                    },
                  ]}
                />
              ) : null}
            </div>
          </div>
        </div>

        <div className="py-16 bg-gray-900">
          <div className="max-w-xs md:max-w-2xl lg:max-w-4xl mx-auto">
            <div className="text-2xl font-medium text-gray-100 text-center mb-2">
              Testimonials
            </div>
            <div className="text-sm text-center text-gray-600 max-w-xl mb-8 mx-auto">
              We are trusted Monitoring Partner of leading infrastructure
              organisations.
            </div>
            <Testimonials testimonials={TESTIMONIALS} />
          </div>
        </div>

        <div className="py-4 bg-gray-200">
          <div className="max-w-4xl mx-auto">
            <div className="flex flex-wrap md:flex-no-wrap items-center justify-center md:justify-between">
              {CLIENTS.map(client => (
                <div className="py-2 md:py-0" key={client}>
                  <img src={client} alt="" className="w-32 h-16" />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="py-16 bg-white" id="products">
          <div className="max-w-xs md:max-w-2xl lg:max-w-4xl mx-auto">
            <div className="text-2xl md:text-3xl lg:text-4xl font-black text-gray-800 text-center mb-2">
              Our Products
            </div>
            <div className="text-lg text-center text-gray-600 mb-8 md:mb-16">
              Our suite of products cater for the needs of every organisation
            </div>
            <div className="rounded shadow-md bg-gray-700 overflow-hidden">
              <div className="hidden md:flex">
                <div className="flex-1 p-4" />
                <div className="flex-1 p-4">
                  <div className="text-xs uppercase tracking-wider text-gray-500 font-medium">
                    Project Type
                  </div>
                </div>
                <div className="flex-1 p-4">
                  <div className="text-xs uppercase tracking-wider text-gray-500 font-medium">
                    Number of Projects
                  </div>
                </div>
                <div className="flex-1 p-4" />
              </div>
              {PRODUCTS.map((product, index) => (
                <div
                  className={classNames(
                    'flex flex-col md:flex-row group cursor-pointer py-4 md:py-0',
                    index % 2 === 0 ? 'bg-gray-800' : undefined,
                  )}
                  key={product.name}
                >
                  <div className="flex-1 flex items-center px-6 md:px-4 py-4 md:py-8">
                    <img
                      src={product.image}
                      className="w-6 h-6 ml-0 md:ml-4 mr-4"
                      alt={product.name}
                    />
                    <div className="font-medium text-gray-100 text-lg md:text-base">
                      {product.name}
                    </div>
                  </div>
                  <div className="hidden md:block flex-1 px-6 md:px-4 py-8 text-sm text-gray-300">
                    {product.projectType}
                  </div>
                  <div className="hidden md:block flex-1 px-6 md:px-4 py-8 text-sm text-gray-300">
                    {product.numberOfProjects}
                  </div>
                  <div className="block md:hidden flex-1 px-6 md:px-4 py-2 text-sm text-gray-400">
                    {product.description}
                  </div>
                  <div className="flex-1 px-6 md:px-4 py-4 md:py-8 opacity-75 group-hover:opacity-100 transition-opacity">
                    <BrochureButton brochureLink={product.brochure} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="py-16 bg-gray-900">
          <div className="max-w-xs md:max-w-2xl lg:max-w-4xl mx-auto">
            <div className="text-2xl md:text-3xl lg:text-4xl font-black text-gray-100 text-center mb-2">
              Integrate your tools with BOCS
            </div>
            <div className="text-lg text-center text-gray-600 mb-16">
              Setup your project monitoring system by quickly importing from the
              tools you already use
            </div>
            <div className="flex flex-wrap -m-6">
              {PLUGINS.map(plugin => (
                <div
                  key={plugin.name}
                  className="p-6 flex items-center w-full md:w-1/2 hover:shadow"
                >
                  <div className="mr-4 bg-white bordered p-2 rounded">
                    <img
                      src={plugin.image}
                      className="min-w-16 h-16 object-cover"
                      alt={plugin.name}
                    />
                  </div>
                  <div>
                    <div className="font-bold text-gray-200 mb-2">
                      {plugin.name}
                    </div>
                    <div className="text-gray-600 text-sm">
                      {plugin.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="py-16 bg-primary">
          <div className="max-w-xs md:max-w-xl mx-auto">
            <div className="text-3xl font-bold text-white text-center mb-2">
              Get in Touch
            </div>
            <div className="text-sm text-center text-gray-300 mb-8 max-w-xl mx-auto">
              Share your email so BOCS can send you demo link and a scheduled
              meeting with our product team, to get you started with our All
              purpose Project management Tool
            </div>
            <FooterLeadGenerationForm key="footerLead" />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Home;

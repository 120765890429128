import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { animated, useTransition } from 'react-spring';

interface IconProps {
  className?: string;
  style?: React.CSSProperties;
}

interface Props {
  data: Array<{
    icon: React.ComponentClass<IconProps> | React.FC<IconProps>;
    title: string;
    image: string;
    caption: string;
  }>;
  light?: boolean;
}

const FeaturesListMobile: React.FC<Props> = ({ data, light }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const transitions = useTransition(data[activeIndex], item => item.image, {
    from: { opacity: 0, transform: 'translate3d(100%, 0%, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%, 0, 0)' },
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setActiveIndex(activeIndexState => (activeIndexState + 1) % data.length);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [data.length, activeIndex]);

  return (
    <div className={classNames('relative flex-1 h-125 overflow-hidden')}>
      {transitions.map(({ item, key, props }) => {
        const { icon: Icon, title, caption, image } = item;
        return (
          <animated.div
            key={key}
            className="absolute inset-0 flex flex-col"
            style={props}
          >
            <div
              className={classNames(
                'cursor-pointer transition-opacity w-full px-4 py-4 rounded select-none mb-6',
                classNames(
                  'opacity-100 shadow',
                  light ? 'bg-white border border-primary' : 'bg-gray-800',
                ),
              )}
            >
              <div className="flex items-center justify-center mb-6">
                <Icon className="w-6 h-6 mr-3" />
                <div
                  className={classNames(
                    'text-center text-lg font-medium',
                    light ? 'text-gray-800' : 'text-gray-300',
                  )}
                >
                  {title}
                </div>
              </div>
              <div
                className={classNames(
                  'text-sm text-center',
                  light ? 'text-gray-700' : 'text-gray-500',
                )}
              >
                {caption}
              </div>
            </div>
            <div className="flex-1 relative overflow-hidden">
              <img
                src={image}
                alt={title}
                className="absolute inset-0 w-2/3 mx-auto"
              />
            </div>
          </animated.div>
        );
      })}
    </div>
  );
};

FeaturesListMobile.defaultProps = {
  light: false,
};

export default FeaturesListMobile;

const TESTIMONIALS = [
  {
    id: 1,
    author: {
      name: 'V K Panjiyar',
      designation: 'DRM',
      company: 'Varanasi Division',
      profileImage: require('../images/testimonials/vk-panjiyar.png'),
    },
    testimonial:
      'We were very impressed with the speed with which BOCS understood our requirements and delivered a monitoring solution which exceeded our expectations.',
    image: require('../images/testimonials/testimonial-1.png'),
  },
  {
    id: 2,
    author: {
      name: 'Satyam Kumar Singh',
      designation: 'JGM Projects',
      company: 'RVNL Varanasi',
      profileImage: require('../images/testimonials/satyam-kumar.png'),
    },
    testimonial: `We're engaged with BOCS from its initial days. Now we can track our Mega Projects on mobile.`,
    image: require('../images/testimonials/testimonial-2.png'),
  },
  {
    id: 3,
    author: {
      name: 'Piyush Singh',
      designation: 'GM RE',
      company: 'RVNL Varanasi',
      profileImage: require('../images/testimonials/piyush-singh.jpeg'),
    },
    testimonial:
      'With implementation of BOCS we have achieved unprecedented project visibility   leading towards faster delivery of our Railway Electrification project.',
    image: require('../images/testimonials/testimonial-3.jpeg'),
  },
];

export default TESTIMONIALS;

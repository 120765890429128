import { useState, useEffect } from 'react';

const useMedia = () => {
  const [deviceSize, setDeviceSize] = useState<
    'xs' | 'sm' | 'md' | 'lg' | 'xl'
  >('xl');

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window;

      if (innerWidth < 640) {
        setDeviceSize('xs');
      } else if (innerWidth < 768) {
        setDeviceSize('sm');
      } else if (innerWidth < 1024) {
        setDeviceSize('md');
      } else if (innerWidth < 1280) {
        setDeviceSize('lg');
      } else {
        setDeviceSize('xl');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    isMobile: deviceSize === 'xs' || deviceSize === 'sm',
    isTab: deviceSize === 'md' || deviceSize === 'lg',
    isDesktop: deviceSize === 'xl',
  };
};

export default useMedia;

import React, { useRef, useState, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useSpring, animated } from 'react-spring';
import { MdClose } from 'react-icons/md';
import { v4 } from 'uuid';

interface Props {
  visible?: boolean;
  onDismiss?: () => void;
  children: JSX.Element;
  title?: string;
}

const Modal: React.FC<Props> = ({ visible, onDismiss, children, title }) => {
  const id = useRef(v4());

  const portalContainer = useRef<HTMLDivElement | null>(null);

  const wrapper = useRef<HTMLDivElement | null>(null);

  const [portalContainerMounted, setPortalContainerMounted] = useState(false);

  const {
    opacity: wrapperOpacity,
    pointerEvents: wrapperPointerEvents,
  } = useSpring({
    opacity: visible ? 1 : 0,
    pointerEvents: visible ? 'auto' : 'none',
  });

  const {
    transform: contentContainerTransform,
    opacity: contentContainerOpacity,
  } = useSpring({
    transform: visible ? 'translate3d(0, 0, 0)' : 'translate3d(0, -100%, 0)',
    opacity: visible ? 1 : 0,
  });

  const handleWrapperClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.target === wrapper.current && onDismiss) {
        onDismiss();
      }
    },
    [onDismiss],
  );

  useEffect(() => {
    portalContainer.current = document.createElement('div');
    portalContainer.current.classList.add('modal-container');
    document.body.appendChild(portalContainer.current);
    setPortalContainerMounted(true);

    return () => {
      if (portalContainer.current) {
        document.body.removeChild(portalContainer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (visible) {
      window.location.hash = `modal-${id.current}`;
    } else {
      window.location.hash = '';
    }
  }, [visible]);

  useEffect(() => {
    const handleHashChange = (event: HashChangeEvent) => {
      if (
        event.oldURL.includes(`modal-${id.current}`) &&
        visible &&
        onDismiss
      ) {
        onDismiss();
      }
    };
    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [onDismiss, visible]);

  if (!portalContainerMounted) {
    return null;
  }

  return createPortal(
    <animated.div
      style={{ opacity: wrapperOpacity, pointerEvents: wrapperPointerEvents }}
      className="fixed inset-0 bg-dark-opacity-75 flex items-center justify-center"
      ref={wrapper}
      onClick={handleWrapperClick}
    >
      <animated.div
        className="min-w-xs max-w-xs md:min-w-xl md:max-w-xl overflow-hidden rounded shadow bg-white"
        style={{
          transform: contentContainerTransform,
          opacity: contentContainerOpacity,
        }}
      >
        <div className="pl-4 py-2 flex justify-between border-b">
          <div className="text-gray-600 text-base font-bold tracking-wide">
            {title}
          </div>
          <div className="-my-2">
            <button
              className="bg-gray-300 text-gray-800 flex items-center justify-center h-full py-2 px-3 focus:outline-none"
              onClick={onDismiss}
            >
              <MdClose />
            </button>
          </div>
        </div>
        <div className="p-4">{children}</div>
      </animated.div>
    </animated.div>,
    portalContainer.current as HTMLDivElement,
  );
};

export default Modal;

const PRODUCTS = [
  {
    name: 'Compass',
    projectType: 'Construction Projects',
    numberOfProjects: 'Multiple',
    image: require('../images/products/compass.png'),
    description:
      'If your organization executes multiple construction projects involving many departments, then this product is for you.',
    brochure:
      'https://firebasestorage.googleapis.com/v0/b/bocs-frontend.appspot.com/o/brochures%2FBOCS%20Compass.pdf?alt=media&token=48ce9e31-f4de-4b1c-8199-9dc3564ee1a9',
  },
  {
    name: 'Projects',
    projectType: 'Big Infra / EPC Projects',
    numberOfProjects: 'Single',
    image: require('../images/products/projects.png'),
    description:
      'If your organization executes Big Infra Projects/ EPC Contracts, (additional text to fill space), then this product is for you.',
    brochure:
      'https://firebasestorage.googleapis.com/v0/b/bocs-frontend.appspot.com/o/brochures%2FBOCS%20Project.pdf?alt=media&token=e8dfba87-9842-4166-99c6-a998e986ee51',
  },
  {
    name: 'Agile',
    projectType: 'Metro / Mono Rail / Rapid Rail',
    numberOfProjects: 'Single',
    image: require('../images/products/agile.png'),
    description:
      'If your organization executes Metro/ Rapid Rail/ Mono Rail/ High Speed Railway Projects, then this product is for you.',
    brochure:
      'https://firebasestorage.googleapis.com/v0/b/bocs-frontend.appspot.com/o/brochures%2FBOCS%20Agile.pdf?alt=media&token=518dd687-6090-4efc-a426-1db1d66bcab9',
  },
  {
    name: 'PMC',
    projectType: 'Survey Design / Consulancy Projects',
    numberOfProjects: 'Multiple',
    image: require('../images/products/pmc.png'),
    description:
      'If your organization handles multiple contracts of Survey, Design, Consultancy etc., then this product is for you.',
  },
];

export default PRODUCTS;

import React, { useCallback, useContext, useState } from 'react';
import LeadInfoContext from '../../contexts/LeadInfoContext';
import Modal from '../Modal';
import LeadGenerationForm from '../LeadGenerationForm';

interface Props {
  brochureLink?: string;
}

const BrochureButton: React.FC<Props> = ({ brochureLink }) => {
  const { leadDataCollected } = useContext(LeadInfoContext);

  const [visible, setVisible] = useState(false);

  const [showContactMessage, setShowContactMessage] = useState(false);

  const handleDownloadBrochure = useCallback(() => {
    if (brochureLink) {
      window.open(brochureLink, '_blank');
    } else {
      if (leadDataCollected) {
        setVisible(true);
        setShowContactMessage(true);
      } else {
        setVisible(true);
        setShowContactMessage(false);
      }
    }
  }, [brochureLink, leadDataCollected]);

  const handleModalClose = useCallback(() => {
    setVisible(false);
    setShowContactMessage(false);
  }, []);

  const handleSuccess = useCallback(() => {
    if (brochureLink) {
      setVisible(false);
      window.open(brochureLink, '_blank');
    } else {
      setVisible(true);
      setShowContactMessage(true);
    }
  }, [brochureLink]);

  return (
    <>
      <button
        type="button"
        className="px-2 py-1 rounded bg-gray-400 text-center"
        onClick={handleDownloadBrochure}
      >
        <span className="text-xs text-center uppercase mr-4 ml-2 font-medium text-gray-800">
          Know More
        </span>
      </button>
      <Modal visible={visible} onDismiss={handleModalClose} title="Get Info">
        {showContactMessage ? (
          <div>
            <div className="text-lg font-bold text-gray-800">
              Our team will get in touch with you soon
            </div>
          </div>
        ) : (
          <LeadGenerationForm
            onSuccess={handleSuccess}
            buttonLabel="Book a Demo"
          />
        )}
      </Modal>
    </>
  );
};

export default BrochureButton;

import React, { useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';
import LeadGenerationForm from '../LeadGenerationForm';

const FooterLeadGenerationForm: React.FC = () => {
  const { addToast } = useToasts();

  const handleSuccess = useCallback(() => {
    addToast('Our team will contact you soon', {
      appearance: 'success',
      autoDismiss: true,
    });
    // window.open('https://demo.bocs.work?leadInfoCaptured', '_blank');
  }, [addToast]);

  return (
    <div className="bg-white-opacity-25 p-4 rounded">
      <LeadGenerationForm key="leadForm" onSuccess={handleSuccess} />
    </div>
  );
};

export default FooterLeadGenerationForm;
